import { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunctionArgs,
  useLocation,
  useRouteLoaderData
} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import { toast as showToast } from 'sonner';

import { GeneralErrorBoundary } from '~/components/error-boundary';
import { Toaster } from '~/components/ui/sonner';
import { TooltipProvider } from '~/components/ui/tooltip';
import { getPublicEnv } from '~/lib/env.server';
import { identifyUser, trackPageView } from '~/lib/events.client';
import { readAuthFromRequest } from '~/lib/session.server';
import { Toast, getToastFromRequest } from '~/lib/toast.server';

import tailwind from './tailwind.css?url';

// TODO: find a more explicit way to force revalidation when the response has toast headers
export function shouldRevalidate({
  actionStatus,
  defaultShouldRevalidate
}: ShouldRevalidateFunctionArgs) {
  if (actionStatus === 500) return true;
  return defaultShouldRevalidate;
}

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: tailwind },
    {
      rel: 'preload',
      href: '/fonts/inter.woff2',
      as: 'font',
      type: 'font/woff2',
      crossOrigin: 'anonymous'
    }
  ];
};

export async function loader({ request, response }: LoaderFunctionArgs) {
  const auth = await readAuthFromRequest(request);
  if (auth) {
    Sentry.setUser({
      email: auth.user.email
    });
  }

  const { toast, setCookieHeader } = await getToastFromRequest(request);
  if (setCookieHeader) {
    response!.headers.append('Set-Cookie', setCookieHeader);
  }

  return {
    ENV: getPublicEnv(),
    user: auth?.user,
    toast
  };
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const location = useLocation();

  useToast(data?.toast);

  useEffect(() => {
    // Note: this will only identify users that are logged in on initial page load
    if (data?.user) {
      identifyUser({
        email: data.user.email,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        id: data.user.id
      });
    }
  }, [data?.user]);

  useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <html lang="en" className="h-full antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (window.heap = window.heap || []),
              (heap.load = function (e, t) {
                (window.heap.appid = e), (window.heap.config = t = t || {});
                for (
                  var n = function (e) {
                      return function () {
                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                      };
                    },
                    p = [
                      'addEventProperties',
                      'addUserProperties',
                      'clearEventProperties',
                      'identify',
                      'resetIdentity',
                      'removeEventProperty',
                      'setEventProperties',
                      'track',
                      'unsetEventProperty',
                    ],
                    o = 0;
                  o < p.length;
                  o++
                )
                  heap[p[o]] = n(p[o]);
              });
              heap.load('${ENV.HEAP_APP_ID}');
            `
          }}
        />
      </head>
      <body className="h-full min-h-full overscroll-y-none">
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`
          }}
        />
        <Toaster />
        <ScrollRestoration />
        <Scripts />
        {['develop', 'production'].includes(ENV.ENVIRONMENT ?? '') && (
          <script
            type="text/javascript"
            async
            src={`https://cdn.heapanalytics.com/js/heap-${ENV.HEAP_APP_ID}.js`}
          />
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: `window.HFCHAT_CONFIG = {
              EMBED_TOKEN: 'e7c1aa70-3231-11ef-8a0c-11e71e3a3557',
              ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
              onload: function() { window.HappyFoxChat = this; }
            };`
          }}
        ></script>
        {['develop', 'production'].includes(ENV.ENVIRONMENT ?? '') && (
          <script
            type="text/javascript"
            src="https://widget.happyfoxchat.com/v2/visitor/js/widget-loader.js"
            async
          ></script>
        )}
      </body>
    </html>
  );
}

function App() {
  return (
    <TooltipProvider delayDuration={500}>
      <Outlet />
    </TooltipProvider>
  );
}

export default Sentry.withSentry(App);

function useToast(toast?: Toast | null) {
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        showToast[toast.type](toast.title, {
          id: toast.id,
          description: toast.description,
          duration: toast.duration
        });
      }, 0);
    }
  }, [toast]);
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
