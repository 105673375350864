import { Toaster as Sonner } from 'sonner';

import { cn } from '~/lib/styles';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      cn={cn}
      expand
      closeButton
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-input group-[.toaster]:shadow-lg',
          title: 'group-[.toast]:text-foreground font-medium text-sm',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          closeButton:
            'bg-background border group-[.toaster]:border-input shadow-sm',
          icon: 'group-data-[type=error]:text-destructive group-data-[type=success]:text-success group-data-[type=warning]:text-warn group-data-[type=info]:text-info'
        }
      }}
      {...props}
    />
  );
};

export { Toaster };
